
export default [
    {
        path: '/informs/article',
        name: 'Article',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Article')
      },
      {
        path: '/informs/ask',
        name: 'Ask',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Ask')
      },
      {
        path: '/informs/present',
        name: 'Present',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Present')
      },
      {
        path: '/informs/newspages/anynews',
        name: 'Anynews',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/Anynews')
      },
]