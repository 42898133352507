export default {
    actions: {
        async getAllProductsblocks(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllProductsblocks";
                }
            }
            const getProductsblocksAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {
                        if (response.status == "200") {
                            ctx.commit("updateAllProductsblocks", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getProductsblocksAxios(url);
                        }
                        count++;
                    });
            }

            await getProductsblocksAxios(url, ctx);
        }
    },
    mutations: {
        updateAllProductsblocks(state, Productsblocks) {
            state.Productsblocks = Productsblocks;
        },

    },
    state: {
        Productsblocks: [],
    },
    getters: {
        getProductsblocks(state) {
            return state.Productsblocks;
        },
    }

}