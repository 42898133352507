export default {
    actions: {
        async getAllNewsblocks(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllNewsblocks";
                }
            }
            const getNewsblocksAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {
                        if (response.status == "200") {
                            ctx.commit("updateAllNewsblocks", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getNewsblocksAxios(url);
                        }
                        count++;
                    });
            }

            await getNewsblocksAxios(url, ctx);
        }
    },
    mutations: {
        updateAllNewsblocks(state, newsblocks) {
            state.newsblocks = newsblocks;
        },

    },
    state: {
        newsblocks: [],
    },
    getters: {
        getNewsblocks(state) {
            return state.newsblocks;
        },
    }

}